import React from "react"
import { Link } from "gatsby"
import { SITE_TITLE } from "../constant"
import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFound = () => {
  return (
    <Layout>
      <SEO></SEO>
      <h1 className="d-none">{SITE_TITLE}</h1>
      <div className="text-center mt-5">
        <h2 >Page Not Found</h2>
        <Link to="/">Go back</Link>
      </div>
    </Layout>
  )
}

export default NotFound
